import { render, staticRenderFns } from "./Tasks.vue?vue&type=template&id=0ef4757c&scoped=true&"
import script from "./Tasks.vue?vue&type=script&lang=js&"
export * from "./Tasks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ef4757c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VAppBarTitle } from 'vuetify/lib/components/VAppBar';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VSlideXTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VAppBar,VAppBarNavIcon,VAppBarTitle,VChip,VDivider,VIcon,VList,VSlideXTransition,VSpacer,VSubheader})
