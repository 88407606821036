<template>
  <div>
    <v-app-bar app dense color="orange" dark class="overflow-visible">
      <v-app-bar-nav-icon @click="requestDrawer"></v-app-bar-nav-icon>
      <v-app-bar-title class="pl-2">
        <v-chip small
                label
                @click="deselectTag(tag)"
                v-for="tag in selectedTags"
                :key="tag.id"
                :color="`${tag.color} lighten-2`"
                dark
                class="ml-2"
                >
          {{tag.text}}
          <v-icon x-small class="ml-2">$delete</v-icon>
        </v-chip>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <new-item-icon @addTaskRequested="addTaskRequested"
                     @addEventRequested="addEventRequested"
                     @addContactRequested="addContactRequested"
                     @addFileRequested="addFileRequested"></new-item-icon>
    </v-app-bar>
    <v-list subheader>
      <v-subheader inset>Unscheduled</v-subheader>
      <v-divider></v-divider>
      <draggable v-model="unscheduledTasks" group="tasks" handle=".handle">
        <v-slide-x-transition group mode="out-in">
          <task-list-item v-for="task in unscheduledTasks"
                          :key="task.id"
                          :task="task"
                          @taskDetails="showDetails($event.task)" />
        </v-slide-x-transition>
      </draggable>
      <task-details :trigger="detailsOpen" :task="detailTask" @deleteRequested="deleteTask" @editRequested="editTask" @closeRequested="detailsOpen = false"></task-details>
      <event-editor v-show="newLinkedEvent" :link-item="itemToLink" @closeRequested="newLinkedEvent = false"></event-editor>
    </v-list>
  </div>
</template>

<script>
import TaskListItem from '@/components/listItems/TaskListItem'
import TaskDetails from '@/components/details/TaskDetails'
import taskApi from '@/firebase/taskApi'
import authenticator from '@/firebase/authenticator'
import { db } from '@/firebase/firestore'
import EventEditor from '@/components/editors/EventEditor'
import NewItemIcon from '@/components/icons/NewItemIcon'
import draggable from 'vuedraggable'
import TagCoordinator from '@/components/services/TagCoordinator'

export default {
  name: 'Tasks',
  created () {
    authenticator.getCurrentUser().then((user) => {
      this.$bind('tasks', db.collection(`users/${user.id}/tasks`))
      this.user = user
    })
  },
  components: { EventEditor, TaskDetails, TaskListItem, NewItemIcon, draggable },
  data: () => ({
    tasks: [],
    user: null,
    searching: false,
    searchText: '',
    detailTask: {
      date: { }
    },
    taskToEdit: {},
    editingTask: false,
    itemToLink: null,
    newLinkedEvent: null,
    detailsOpen: false,
    selectedTags: TagCoordinator.selectedTags
  }),
  computed: {
    unscheduledTasks: {
      get () {
        return this.getScheduledTasks(this.selectedTags)
      },
      // only called by drag-n-drop sorting
      set (value) {
        value.forEach((t, i) => {
          this.tasks.find(task => t.id === task.id).order = i
        })
        this.tasks.forEach(t => {
          taskApi.saveTask(t)
        })
      }
    }
  },
  methods: {
    deselectTag (tag) {
      TagCoordinator.deselectTag(tag)
    },
    getScheduledTasks () {
      return this.tasks
        .filter(t => !t.date && !t.daily)
        .filter(t => TagCoordinator.itemTagSelected(t))
        .sort((a, b) => (a.order || 0) < (b.order || 0) ? -1 : 1)
    },
    showDetails: function (task) {
      this.detailTask = task
      this.detailsOpen = true
    },
    updateTask (task) {
      taskApi.saveTask(task)
    },
    editTask (task) {
      this.$emit('editTask', task)
      this.detailsOpen = false
    },
    async deleteTask (task) {
      await taskApi.deleteTask(task.id)
      this.detailsOpen = false
    },
    addTaskRequested () {
      this.$emit('addTaskRequested')
    },
    addEventRequested () {
      this.$emit('addEventRequested')
    },
    addContactRequested () {
      this.$emit('addContactRequested')
    },
    addFileRequested () {
      this.$emit('addFileRequested')
    },
    requestDrawer () {
      this.$emit('showDrawer')
    }
  }
}
</script>

<style scoped>

</style>
